// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BuildOutlined,
  CalendarOutlined,
  CustomerServiceOutlined,
  FileTextOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  DashboardOutlined,
  FileOutlined,
  AreaChartOutlined,
  ProfileOutlined,
} from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  BuildOutlined,
  CalendarOutlined,
  CustomerServiceOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  FileTextOutlined,
  DashboardOutlined,
  FileOutlined,
  AreaChartOutlined,
  ProfileOutlined,
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications: NavItemType = {
  id: 'group-applications',
  title: '',
  icon: icons.AppstoreAddOutlined,
  type: 'group',
  children: [
    {
      id: "Dashboard",
      title: <FormattedMessage id="dashboard" />,
      type: "item",
      url: "/dashboard",
      icon: AreaChartOutlined,
      target: false,
      rights: "ngtRequest",
    },
    {
      id: 'Overview',
      title: <FormattedMessage id='overview' />,
      type: 'item',
      url: '/overview',
      icon: ProfileOutlined,
      target: false,
      rights: 'ngtRequest',
    },
    {
      id: 'Compare Templates',
      title: <FormattedMessage id='compare_templates' />,
      type: 'item',
      url: '/compareTemplates',
      icon: FileOutlined,
      target: false,
      rights: 'ngtTemplate',
    },
  ],
};

export default applications;
