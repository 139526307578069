import { createSlice } from "@reduxjs/toolkit";

export const initialState: any = {
  page_number: 1,
  records_per_page: 10,
};

const pagination = createSlice({
  name: "paginationData",
  initialState,
  reducers: {
    setPaginationData(state, action) {
      state.page_number = action.payload.page_number;
      state.records_per_page = action.payload.records_per_page;
    },
  },
});

export default pagination.reducer;

export const { setPaginationData } = pagination.actions;
