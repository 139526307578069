import { createSlice } from "@reduxjs/toolkit";

export const initialState: any = {
  data: {},
};

const translation = createSlice({
  name: "translationData",
  initialState,
  reducers: {
    setTranslation(state, action) {
      state.data = action.payload;
    },
  },
});

export default translation.reducer;

export const { setTranslation } = translation.actions;
