import axios, { AxiosInstance } from "axios";
const host = window.location.host;
const parts = host.split(".");
parts.shift();
let domain = parts.join('.');
const axiosServices: AxiosInstance = axios.create({
  baseURL: JSON.parse(process.env.REACT_APP_API_URL!)[domain] || "http://localhost:3010/",
});

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.request.use(
  (request) => {
      request.withCredentials = true;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (
    //   error.response.status === 401 &&
    //   !window.location.href.includes("/login")
    // ) {
    //   window.location.pathname = "/login";
    // }
    return Promise.reject(
      (error.response && error.response.data) || "Wrong Services"
    );
  }
);

export default axiosServices;
