import { createContext, ReactNode, useEffect } from 'react';

// project import
import config from 'config';
import useLocalStorage from 'hooks/useLocalStorage';

// types
import { CustomizationProps, FontFamily, I18n, MenuOrientation, PresetColor, ThemeDirection, ThemeMode } from 'types/config';
declare var $: any;

// initial state
const initialState: CustomizationProps = {
  ...config,
  onChangeContainer: () => { },
  onChangeLocalization: (lang: I18n) => { },
  onChangeMode: (mode: ThemeMode) => { },
  onChangePresetColor: (theme: PresetColor) => { },
  onChangeDirection: (direction: ThemeDirection) => { },
  onChangeMiniDrawer: (miniDrawer: boolean) => { },
  onChangeMenuOrientation: (menuOrientation: MenuOrientation) => { },
  onChangeFontFamily: (fontFamily: FontFamily) => { }
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext(initialState);

type ConfigProviderProps = {
  children: ReactNode;
};

function ConfigProvider({ children }: ConfigProviderProps) {
  const [config, setConfig] = useLocalStorage('mantis-react-ts-config', initialState);

  useEffect(() => {
    localStorage.setItem("mantis-react-ts-config", JSON.stringify(config))
  }, [config]);

  const onChangeContainer = () => {
    setConfig({
      ...config,
      container: !config.container
    });
  };

  const onChangeLocalization = (lang: I18n) => {
    setConfig({
      ...config,
      i18n: lang
    });
  };

  const onChangeMode = (mode: ThemeMode) => {
    mode=='light' ? localStorage.setItem('sm-skin','smart-style-2') : localStorage.setItem('sm-skin','smart-style-1')
    mode=='light' ?  $('body').removeClass('smart-style-1') : $('body').removeClass('smart-style-2');
    mode=='light' ?  $('body').addClass('smart-style-2') : $('body').addClass('smart-style-1');
    const updatedSettings = {
      ...config,
      mode // Updated mode value
    };
    localStorage.setItem('mantis-react-ts-config', JSON.stringify(updatedSettings));
    setConfig({
      ...config,
      mode
    });
  };

  const onChangePresetColor = (theme: PresetColor) => {
    setConfig({
      ...config,
      presetColor: theme
    });
  };

  const onChangeDirection = (direction: ThemeDirection) => {
    setConfig({
      ...config,
      themeDirection: direction
    });
  };

  const onChangeMiniDrawer = (miniDrawer: boolean) => {
    setConfig({
      ...config,
      miniDrawer
    });
  };

  const onChangeMenuOrientation = (layout: MenuOrientation) => {
    setConfig({
      ...config,
      menuOrientation: layout
    });
  };

  const onChangeFontFamily = (fontFamily: FontFamily) => {
    setConfig({
      ...config,
      fontFamily
    });
  };

  return (
    <ConfigContext.Provider
      value={{
        ...config,
        onChangeContainer,
        onChangeLocalization,
        onChangeMode,
        onChangePresetColor,
        onChangeDirection,
        onChangeMiniDrawer,
        onChangeMenuOrientation,
        onChangeFontFamily
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export { ConfigProvider, ConfigContext };
