import {
  forwardRef,
  useEffect,
  ForwardRefExoticComponent,
  RefAttributes,
} from "react";
import { Link, useLocation } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  useMediaQuery,
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

// project import
import { dispatch, useSelector } from "store";
import { activeComponent, openComponentDrawer } from "store/reducers/menu";
// import { activeItem } from 'store/reducers/menu';

// types
import { LinkTarget, NavItemType } from "types/menu";
import { ThemeMode } from "types/config";

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

interface Props {
  item: NavItemType;
  level: number;
}

const NavItem = ({ item, level }: Props) => {
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  const menu = useSelector((state) => state.menu);
  const { openComponent } = menu;

  let itemTarget: LinkTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps: {
    component:
      | ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>>
      | string;
    href?: string;
    target?: LinkTarget;
  } = {
    component: forwardRef((props, ref) => (
      <Link {...props} to={item.url!} target={itemTarget} ref={ref} />
    )),
  };
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }
  const { drawerOpen, openItem } = menu;
  const Icon = item.icon!;
  const itemIcon = item.icon ? (
    <Icon style={{ fontSize: drawerOpen ? "1rem" : "1.25rem" }} />
  ) : (
    false
  );
  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  const itemHandler = (id: string) => {
    dispatch(activeComponent({ openComponent: id }));
    matchesMD && dispatch(openComponentDrawer({ componentDrawerOpen: false }));
  };

  // active menu item on page load
  // useEffect(() => {
  //   const currentIndex = document.location.pathname
  //     .toString()
  //     .split('/')
  //     .findIndex((id) => id === item.id);
  //   if (currentIndex > -1) {
  //     dispatch(activeComponent({ openComponent: item.id }));
  //   }
  //   // eslint-disable-next-line
  // }, []);

  const { pathname } = useLocation();

  // active menu item on page load
  useEffect(() => {
    // if (pathname && pathname.includes('product-details')) {
    //   if (item.url && item.url.includes('product-details')) {
    //     dispatch(activeItem({ openItem: [item.id] }));
    //   }
    // }

    // if (pathname && pathname.includes('kanban')) {
    //   if (item.url && item.url.includes('kanban')) {
    //     dispatch(activeItem({ openItem: [item.id] }));
    //   }
    // }

    if (pathname === item.url) {
      dispatch(activeComponent({ openComponent: item.id }));
    }
    // eslint-disable-next-line
  }, [pathname]);
  // var retrievedObject: any = localStorage.getItem('mantis-react-ts-config');
  // const updatedVal = JSON.parse(retrievedObject);
  const textColor =
    theme.palette.mode === ThemeMode.DARK ? "grey.400" : "text.primary";
  const iconSelectedColor =
    theme.palette.mode === ThemeMode.DARK ? "text.primary" : "primary.main";
  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      onClick={() => itemHandler(item.id!)}
      selected={
        openComponent === item.id || pathname.includes(item.url as string)
      }
      sx={{
        pl: 1.5,
        py: 1,
        mb: 0.5,
        "&:hover": {
          bgcolor:
            theme.palette.mode === ThemeMode.DARK
              ? "divider"
              : "primary.lighter",
        },
        "&.Mui-selected": {
          bgcolor:
            theme.palette.mode === ThemeMode.DARK
              ? "divider"
              : "primary.lighter",
          borderRight: `2px solid ${theme.palette.primary[500]}`,
          color: iconSelectedColor,
          "&:hover": {
            color: iconSelectedColor,
            bgcolor:
              theme.palette.mode === ThemeMode.DARK
                ? "divider"
                : "primary.lighter",
          },
        },
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 28,
          color: isSelected ? iconSelectedColor : textColor,
          display: "flex", // Ensure that the item icon is always flexibly displayed
          alignItems: "center", // Center align the item icon vertically
          justifyContent: "center", // Center align the item icon horizontally
          ...(!drawerOpen && {
            borderRadius: 1.5,
            width: 36,
            height: 36,
          }),
          ...(!drawerOpen &&
            isSelected && {
              bgcolor:
                theme.palette.mode === ThemeMode.DARK
                  ? "primary.900"
                  : "primary.lighter",
              "&:hover": {
                bgcolor:
                  theme.palette.mode === ThemeMode.DARK
                    ? "primary.darker"
                    : "primary.lighter",
              },
            }),
        }}
      >
        {itemIcon}
      </ListItemIcon>
      {drawerOpen && (
        <ListItemText
          primary={
            <Typography
              variant="h6"
              sx={{
                color:
                  openComponent === item.id ? iconSelectedColor : textColor,
              }}
            >
              {item.title}
            </Typography>
          }
        />
      )}
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

export default NavItem;
