import React, { useEffect } from 'react';
// project import
import useConfig from 'hooks/useConfig';
import { Box } from "@mui/material";

declare var $: any;

const WC: React.FC = () => {
  const { mode, i18n } = useConfig();
  const loadScript = () => {
    return new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');
      script.src = process.env.REACT_APP_WC_URL!;
      // script.src = '/main.js';
      script.onload = () => resolve();
      script.onerror = () => reject(new Error('Script load error'));
      document.body.appendChild(script);
    });
  };
  loadScript()
  useEffect(() => {
    $('#webComponentsProfile').empty();
    const loadUserData = async () => {
      try {
        let lang = i18n=='gr' ? 'de' : i18n;
        const profileData: any = { lang: lang };

        const profileButton = document.createElement('profile-button');
        profileButton.setAttribute('profiledata', JSON.stringify(profileData));
        const content = document.getElementById('webComponentsProfile');
        if (content) {
          content.appendChild(profileButton);
        }
      } catch (error) {
        console.error('Failed to load user data', error);
      }
    };
      loadUserData();
  }, [mode, i18n]);

  return (
    <Box sx={{  ml: 0.75 }}>
      <div className="menu-item webcomponent-profile" >
  <span  className="webComponetsHoverColor webprofileitem">
      <div className="">
          <div className="avatar">
              <div id="webComponentsProfile"></div>             
          </div>
      </div>
  </span>
</div>
</Box>);
};

export default WC;
