// project import
import applications from "./applications";

// types
import { NavItemType } from "types/menu";
// import DashboardDetail from "./dashboardDetail";

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [applications],
};

export default menuItems;
