import { useEffect, useState } from "react";

// project import
import Routes from "routes";
import ThemeCustomization from "themes";

import Loader from "components/Loader";
import Locales from "components/Locales";
import RTLLayout from "components/RTLLayout";
import ScrollTop from "components/ScrollTop";
import Snackbar from "components/@extended/Snackbar";
import Notistack from "components/third-party/Notistack";

import { RootState, dispatch } from "store";
import { fetchMenu } from "store/reducers/menu";

// auth-provider
import { JWTProvider as AuthProvider } from "contexts/JWTContext";
import { setTranslation } from "store/reducers/translation";
import { useSelector } from "react-redux";
import axiosServices from "utils/axios";
import Cookies from "js-cookie";
import { encrypt, decrypt, getCookie, setEncryptedItem } from "utils/commonFunctions";
import { useLocation } from "react-router";
import useConfig from "hooks/useConfig";
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';
declare var $: any;
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const translationData = useSelector(
    (state: RootState) => state.translation.data
  );
  const location = useLocation();
  const { mode } = useConfig();
  useEffect(() => {
    // fetch dashboard menu from API
    let cookieExist = Cookies.get(process.env.REACT_APP_EXPIRE_COOKIE || "");
    const expireCookie: any = getCookie(
      process.env.REACT_APP_EXPIRE_COOKIE || ""
    );
    const userExpireData = decodeURIComponent(expireCookie);
    if(userExpireData) {
      const userDetails = JSON.parse(decrypt(userExpireData));
      setEncryptedItem('partyID',userDetails['partyID']);
      setEncryptedItem('userID',userDetails['userID']);
      setEncryptedItem('accId',userDetails['accId']);
    }
    
    mode=='light' ? localStorage.setItem('sm-skin','smart-style-2') : localStorage.setItem('sm-skin','smart-style-1')
    mode=='light' ?  $('body').removeClass('smart-style-1') : $('body').removeClass('smart-style-2');
    mode=='light' ?  $('body').addClass('smart-style-2') : $('body').addClass('smart-style-1');
    const host = window.location.host;
    const parts = host.split(".");
    parts.shift();    
    let domain = parts.join('.')
    console.log('domain...'+domain);
    if (!cookieExist) {
      const externalUrl =
        `${
          process.env.REACT_APP_ACCOUNT_LOGIN
        }${domain}/#/auth/logout/${encodeURIComponent(encrypt(window.location.href))}` ||
        "/";
      window.location.assign(externalUrl);
    }
    if (location.pathname.includes("/login")) {
      window.location.assign("/");
    }
    dispatch(fetchMenu()).then(() => {
      setLoading(false);
    });
  }, []);

  const getTranslationData = () => {
    axiosServices
      .get("/translations")
      .then((response) => {
        dispatch(setTranslation(response.data.data));
      })
      .catch((error) => {
        console.error("Error fetching API data:", error);
      });
  };

  useEffect(() => {
    if (!Object.keys(translationData).length) {
      getTranslationData();
    }
  }, []);

  if (loading) return <Loader />;

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Notistack>
                  <Routes />
                  <Snackbar />
                </Notistack>
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
